/* NeonLines.css */
.neon-container-left {
  position: fixed;
  top: 0;
  left: 100;
  width: 50%;
  height: 100vh;
  overflow: hidden;
}
.neon-container-right {
  position: fixed;
  top: 0;
  right: 0px;
  width: 50%;
  height: 100vh;
  overflow: hidden;
}

.neon-line {
  width: 7px;
  height: 7px;
  background: linear-gradient(45deg, #ff00ff, #00ffff); /* Neon colors */
  opacity: 0.8; /* Adjust opacity for glow effect */
  mix-blend-mode: overlay; /* Blend mode for neon glow */
  margin-bottom: 200px; /* Adjust the distance between lines */
}
